/**
 * @license
 * MyFonts Webfont Build ID 3836483, 2019-11-12T13:47:55-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvantGardeGothicITCW01Md by ITC
 * URL: https://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-medium/
 * 
 * Webfont: AvantGardeGothicITCW02Bk by ITC
 * URL: https://www.myfonts.com/fonts/itc/avant-garde-gothic/pro-book/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3836483
 * Licensed pageviews: 250,000
 * Webfonts copyright: Font software Copyright 1993, 1994, 2001 Adobe Systems Incorporated. Typeface designs Copyright 2005 International Typeface Corporation. All rights reserved.
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a8a43");

  
@font-face {font-family: 'AvantGardeGothicITCW01Md';src: url('../fonts/3A8A43_0_0.eot');src: url('../fonts/3A8A43_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3A8A43_0_0.woff2') format('woff2'),url('../fonts/3A8A43_0_0.woff') format('woff'),url('../fonts/3A8A43_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'AvantGardeGothicITCW02Bk';src: url('../fonts/3A8A43_1_0.eot');src: url('../fonts/3A8A43_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/3A8A43_1_0.woff2') format('woff2'),url('../fonts/3A8A43_1_0.woff') format('woff'),url('../fonts/3A8A43_1_0.ttf') format('truetype');}
 
// Font Mixins
// ======

@mixin sans-serif-regular {
	font-family: "AvantGardeGothicITCW02Bk", Helvetica, Arial, sans-serif;
	font-style: normal;
}

@mixin sans-serif-italic {
	font-family: "AvantGardeGothicITCW02Bk", Helvetica, Arial, sans-serif;
	font-style: italic;
}

@mixin sans-serif-semi {
	font-family: "AvantGardeGothicITCW01Md", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin sans-serif-bold {
	font-family: "AvantGardeGothicITCW01Md", Helvetica, Arial, sans-serif;
	font-style: normal;
}

@mixin sans-serif-xbold {
	font-family: "AvantGardeGothicITCW01Md", Helvetica, Arial, sans-serif;
	font-weight: normal;
}

@mixin sans-serif-mono {
	font-family: "AvantGardeGothicITCW01Md", Helvetica, Arial, sans-serif;
	font-style: normal;
}

@mixin serif {
	font-family: Georgia, Times, serif;
	font-style: normal;
	font-weight: 400;
}

@mixin serif-bold {
	font-family: Helvetica, Arial, sans-serif;
	font-style: bold;
	font-weight: 700;
}