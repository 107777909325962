/**** Global Iconography ****/
.share-button {
	border-radius: 4px;
	color: $white;
	display: inline-block;
	height: 56px;
	padding: 14px $gap*14 0;
	font-size: 14px;
	@include transition(opacity .3s ease-out);

	@include mediaquery(xl) {
		&:hover {
			opacity: .8;
		}
	}

	&.share-facebook {
		background-color: color('social', 'facebook');
	}

	&.share-twitter {
		background-color: color('social', 'twitter');
	}
}

.social-media {
	text-align: left;

	&.align-center {
		text-align: center;
	}
}

.social {
	border-radius: 50%;
	background: color('background', 'navy');
	color: $white;
	display: inline-block;
	font-size: 14px;
	height: 32px;
	margin: 0 $gap;
	opacity: 1;
	padding-top: 9px;
	text-align: center;
	width: 32px;
	@include transition(opacity .3s ease-out);

	@include mediaquery(xl) {
		width: 40px;
		height: 40px;
		font-size: 18px;
		margin: 0 $gap*2.5;
		padding-top: 11px;

		&:hover {
			opacity: .8;
		}
	}

	&:first-child {
		margin-left: 0;
	}

	&.social-white {
		background-color: $white;
		color: color('background', 'navy');
	}
}

.play {
	border-width: 19px 0 19px 38px;
	border-style: solid;
	border-color: transparent transparent transparent #ffffff;
	cursor: pointer;
	height: 0;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: calc(50% - 19px);
	width: 0;
	z-index: 12;
}

.hamburger-wrapper, .search-wrapper {
	position: absolute;
	height: 65px;
	width: 65px;
	top: 0;
	right: 0;
}

.search-wrapper {
	right: 65px;
	cursor: pointer;
	z-index: 10;

	@include mediaquery(xl) {
		display: none;
	}

	.search-icon {
		margin: 21px 47px 0;
		width: 22px;
		height: 25px;
		fill: color('background', 'navy');

	}
}

.hamburger {
	background-color: transparent;
	cursor: pointer;
	display: block;
	height: 18px;
	border-top: 2px solid black;
	width: 24px;
	margin: 23px auto 0;
	position: relative;
	z-index: 100;
	@include transition(background-color .2s ease-in);

	@include mediaquery(xl) {
		display: none;
	}

	&:after, &:before {
		background-color: black;
		content: '';
		height: 2px;
		left: 0;
		position: absolute;
		top: 6px;
		width: 100%;
		@include transition(all .2s ease-in);
	}

	&:after {
	  top: 14px;
	}

	.navOpen & {
		border-top: 0;

		&:after {
			top: 8px;
			@include transform-rotate(45deg);
		}

		&:before {
			top: 8px;
			@include transform-rotate(-45deg);
		}

	}
}

.close {
	cursor: pointer;
	display: block;
	width: 40px;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 0;
	z-index: 100;

	&:after, &:before {
		content: '';
		position: absolute;
		background-color: color('background', 'navy');
		height: 2px;	
		width: 18px;		
	}

	&:after {
		top: 19px;
		left: 11px;
		@include transform-rotate(45deg);
	}

	&:before {
		top: 19px;
		left: 11px;
		@include transform-rotate(-45deg);
	}

	&.close-white {

		&:after, &:before {
			background-color: $white;
		}
	}

	&.close-small {
		@include transform(scale(.5));
	}
}

.wave {
	display: block;
	margin: $gap*5 auto $gap*6;
	background: url('../images/wave.svg') no-repeat center center;
	background-size: contain;
	width: 40px;
	height: 6px;
}

.pdf-link, .play-link {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 40px;
	opacity: 1;
	transition: opacity .3s ease-in;

	@include mediaquery(xl) {
		&:hover {
			opacity: .7;
		}
	}
}




// Accessibility trick to hide span nearby with aria values
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
	position: absolute;
    width: 1px;
}
