.component-category-sidebar {
	padding-bottom: $gap*2;
	border-bottom: 1px solid color('background', 'green');


	.categories-grid {
		display: flex;
		flex-wrap: wrap;

		.category-item {
			width: calc(50% - 20px);
			height: 0;
			padding-bottom: calc(50% - 20px);
			position: relative;
			overflow: hidden;
			margin-right: 20px;

			&:nth-child(2n) {
				margin-right: 0px;
			}


			@include mediaquery(medium) {
				width: calc(34.2% - 20px);
				padding-bottom: calc(34.2% - 20px);
				
				&:nth-child(2n) {
					margin-right: 20px;
				}

				&:nth-child(3n) {
					margin-right: 0;
				}
			}

			@include mediaquery(large) {
				width: calc(15% - 20px);
				padding-bottom: calc(15% - 20px);
				
				&:nth-child(3n) {
					margin-right: 20px;
				}

				&:nth-child(6n) {
					margin-right: 0;
				}
			}



			img {
				position: absolute;
				top: -50%;
				left: -50%;
				transform: translate(50%, 50%);
			}

			.headline4 {
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 10;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px;
				text-align: center;
				background: rgba(0, 0, 0, .3);
				opacity: 1;
				transition: opacity .3s ease-in;

				@include mediaquery(xl) {
					opacity: 0;
					background: rgba(0, 0, 0, .6);
				}
			}

			&:hover {
				@include mediaquery(xl) {
					.headline4 {
						opacity: 1;
					}
				}
			}
		}
	}
}