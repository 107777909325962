.component-slider {
	margin-bottom: $gap*3;
	position: relative;

	.slides {
		height: 0;
		overflow: hidden;
		padding-bottom: 80%;

		.slide {
			left: 0;
			position: absolute;
			top: 0;
			opacity: 0;
			z-index: -1;
			transition: z-index .3s ease-in, opacity .3s ease-in;

			&.activeSlide {
				opacity: 1;
				z-index: 10;
			}
		}
	}

	.slides-nav {
		margin-top: $gap;
		text-align: center;

		.slide-nav {
			background: rgba(67, 67, 67, .3);
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			height: 10px;
			margin: 0 10px 10px;
			transition: background .3s ease-in;
			width: 10px;

			&.activeSlide {
				background: color('background', 'green');
			}

			&:hover {
				@include mediaquery(xl) {
					background: color('background', 'green');
				}
			}
		}
	}
}