/**** Padding ****/

$positions: top, left, bottom, right;
$screensizes: small, medium, large, xl;
$increment: 20;
// Creates classes based on variables
// Use class "padding-size-position-xx" for paddings used in all screen sizes
//ie: padding-small-top-1x where the number before x is the multiplier for the increment (avoids having to remember your multiplication tables :) )
@each $position in $positions{
	@each $screensize in $screensizes{
		@for $i from 0 through 50 {
			@if $i % 1 == 0 {

				.padding-#{$screensize}-#{$position}-#{$i}x { 
					@include mediaquery(#{$screensize}) {
						padding-#{$position}: #{$i * $increment}px;
					}
				}
				
			}
		}
	}
}