


// Grid and Responsive
// ===================
$gap						: 20px;
$site-width					: 1024px;
$site-width-inner			: 1024px;
$nav-height					: 72px;
$nav-height-scrolled		: 100px;
$nav-height-desktop			: 100px;

// Breakpoints
$small-breakpoint			: 0px;
$medium-breakpoint			: 768px;
$large-breakpoint			: 1024px;
$xl-breakpoint				: 1100px;
$xl2-breakpoint				: 1280px;
$xl3-breakpoint				: 1440px;
$xl4-breakpoint				: 1500px;
$xl5-breakpoint				: 1700px;

// Max width breakpoints
$max-small-breakpoint		: 568px;
$max-medium-breakpoint		: 767px;
$max-large-breakpoint		: 1023px;
$max-xl-breakpoint			: 1099px;
$max-2xl-breakpoint			: 1279px;
$max-3xl-breakpoint			: 1439px;
$max-4xl-breakpoint			: 1499px;
$max-5xl-breakpoint			: 1699px;

//Fixed row heights
$small-row-mobile			: 100vh;
$small-row					: 250px;
$medium-row-mobile			: 100vh;
$medium-row					: 250px;
$large-row-mobile			: 100vh;
$large-row					: 250px;

// Colors
// ======
$brand-color-key: 'red' !default;
$brand-colors: (
	'debug': (
		'red': 				#f00
	),
	'background': (
		'white':			#FFFEFB,
		'green': 			#a2c3a7,
	),
	'text':(
		'black':			#000000,
		'pale-gray':		rgba(0, 0, 0, .5),
		'link':				#a2c3a7
	),
	'social':(
		'facebook':			#3b5998,
		'twitter':			#1da1f2
	),
	'misc':(
		'black-opacity': 	rgba(0, 0, 0, .82)
	)
);

// Standard colors
// ===============
$white: #FFFFFF;
$black: #000000;

@function color($key: 'debug', $variant: $brand-color-key) {
	$color: null;
	$color-spectrum: map-get($brand-colors, $key);

	@if $color-spectrum {
			$color: map-get($color-spectrum, $variant);
	}

	@return $color;
}

// Utility variables
// ===============

$positions: top, bottom, left, right;
$screensizes: mobile, medium, tablet-landscape, desktop, xl, 2xl;

// Generate background and text color classes based on the color map.
//
// Classes are named as follows:
//
// 		.background-key-variant
// 		.color-color-key-variant
//
// For example:
//
// 		.background-green-base
// 		.color-color-high-textDull
//
@each $key, $map in $brand-colors {
	@each $variant, $color in $map {

		.component-theme-#{$key}-#{$variant} {
			background: #{$color};
		}

		.color-#{$key}-#{$variant} {
			color: #{$color};
		}

		.row-text-white {
			.color-#{$key}-#{$variant} {
				color: #fff;
			}
		}

		.row-text-black {
			.color-#{$key}-#{$variant} {
				color: #021B48;
			}
		}
	}
}



// Responsive Font Size Map
// ========================
// $font-size-headline1: (
// 	null  : 48px,
// 	768px : 48px,
// 	1024px: 72px,
// 	1025px: 72px
// );
