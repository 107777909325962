.main-header {
	background: white;
	position:fixed;
	top: 0;
	left: 0;
	padding: 12px 0;
	z-index: 1000;
	background: white;
	transition: top .3s ease-in, border .3s ease-in;
	border-bottom: 1px solid transparent;

	@include mediaquery-max(xl) {
		border-bottom: 1px solid color('background', 'green');
		height: $nav-height;
	}

	@include mediaquery(xl) {
		padding: 12px 20px;
		height: $nav-height-desktop;
	}

	@include mediaquery(xl) {
		padding: 12px 0;
	}

	.hideNav & {
		top: -80px;

		@include mediaquery(xl) {
			top: -120px;
		}
	}

	.showNav & {
		top: 0;
		border-bottom: 1px solid color('background', 'green');
	}

	.header-inner {
		max-width: $site-width;
		margin: 0 auto;
		position: relative;

		@include mediaquery(xl) {
			height: $nav-height;
			padding: 0 $gap*5;
		}


		@media only screen and (min-width: 1360px) {
			padding: 0;
		}
	}

	.logo-wrapper {
		height: 48px;
		display: block;
		position: absolute;
		left: 10px;
		top: 0px;

		@include mediaquery(xl) {
			height: 76px;
		}

		img {
			width: auto;
			height: 100%;
		}
	}

	.main-nav {
		background: rgba(209, 225, 211, 1);
		top: 60px;
		width: 100%;
		position: absolute;
		right: -100vw;
		// right: 0;
		height: calc(100vh - 72px);
		transition: right .3s ease-in;

		@include mediaquery(xl) {
			position: relative;
			right: auto;
			height: 100%;
			width: calc(100% - 250px);
			margin-left: 250px;
			top: auto;
			background: transparent;
		}

		.navOpen & {
			right: 0;
		}
	}

	.menu-items {
		padding: 20px;

		@include mediaquery(xl) {
			padding: 0;
			display: flex;
			height: 100%;
			align-items: center;

		}
	}

	.menu-item {
		font-size: 18px;
		text-transform: uppercase;
		margin-bottom: 15px;
		line-height: 1.2;
		letter-spacing: .5px;
		@include sans-serif-semi;

		@include mediaquery(xl) {
			margin: 0;
			position: relative;
			padding-left: 20px;
			width: 25%;
		}

		&:before {
			content: '';
			position: absolute;
			top: -27px;
			height: 76px;
			background: color('background', 'green');
			width: 1px;
			left:0;
		}

		.sub-menu {
			margin: 15px 0 25px 15px;

			@include mediaquery(xl) {
				overflow: hidden;
				max-height: 0;
				margin: 0;
				background: white;
				transition: max-height .3s ease-in, padding .3s ease-in;
				position: absolute;
				top: 30px;
				left: 0;
				width: 100%;
				padding-top: 0px;
			}

			.menu-item {
				@include mediaquery(xl) {
					font-size: 13px;
					text-transform: none;
					width: 100%;
					margin-bottom: 25px;
					padding-right: 20px;
					color: black;
					transition: color .3s ease-in;
					line-height: 1.4;
				}

				&:hover {
					@include mediaquery(xl) {
						color: color('background', 'green');
					}
				}
			}

		}
		
		&.menu-item-has-children:hover {
			.sub-menu {
				@include mediaquery(xl) {
					max-height: 600px;
					height: auto;
					padding-top: 20px;
				}
			}
		}
	}
}