@import 'vendor';
// @import 'slick.scss';

// General variables and setup
@import 'font';
@import 'vars';
@import 'reset';
@import 'mixins';
@import 'pure-grid';
@import 'grid';

// Global elements
@import 'global';
@import 'typography';
@import 'iconography';
@import 'animations';
@import 'theme';
@import 'padding';
@import 'margin';
@import 'footer';
@import 'nav';


//Components
@import 'components/slider';
@import 'components/categories';