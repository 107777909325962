/**** Interactions & Animations ****/

//===
// Hide from both screenreaders and browsers: h5bp.com/u
//
.fade-me-in {
	opacity: 1;

	&.faded-in {
		opacity: 1;
	}
}

// .component-fade {
// 	height: 100%;
// 	position: relative;
// }

.new-elements {
	//for sliding in ajax loaded content
	opacity: 0;
	// @include transition(opacity 1.3s ease-in);
}

.parallax-me {
	@include transform(translateZ(0));
}

.slideIn {
	-webkit-animation: moveUp 0.65s ease forwards;
	animation: moveUp 0.65s ease forwards;
	opacity: 1;
	@include transform(translateY(200px));
}

.fadeIn {
	animation: fadeIn .7s ease forwards;
}

#lottie {
	display: block;
	height: 100%;
	margin-bottom: 0;
	opacity: 1;
	overflow: hidden;
	transform: translate3d(0,0,0);
	text-align: center;
	width: 100%;

	@include mediaquery(medium) {
		margin-bottom: $gap*1.5;
	}

	@include mediaquery(xl) {
		margin-bottom: $gap*2.25;

		.short-screen & {
			margin-bottom: $gap*1.5;
		}
	}
}

@-webkit-keyframes moveUp {
	0% {}

	100% {
		-webkit-transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		@include transform(scale(.8));
	}

	100% {
		
		opacity: 1;
		@include transform(scale(1));
	}
}


@keyframes moveUp {
	0% {}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@include keyframes(compassSway) {
	0% {
		@include transform(rotate(5deg));
	}

	50% {
		@include transform(rotate(-5deg));
	}

	100% {
		@include transform(rotate(0deg));
	}
}



@include keyframes(peek) {
	0% {
		@include transform(translateX(0));
	}

	50% {
		@include transform(translateX(1%));
	}

	100% {
		@include transform(translateX(0));
	}
}