.main-footer {
	background: rgba(color('background', 'green'), .5);

	.footer-nav {
		max-width: $site-width;
		margin: 0 auto;
		padding:0 $gap;

		@include mediaquery(large) {
			display: flex;
			justify-content: space-between;
		}

		@include mediaquery(xl) {
			padding: 0;
		}
		
		p {
			text-transform: uppercase;
			margin-bottom: 0;
		}

		a {

			@include mediaquery-max(large) {
				display: block;
			}
			
			@extend .body1;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		small {
			line-height: 1.6;
		}
	}



}