/**** Global Text Styles ****/
address {
	font-size: 14px;
	line-height: 2;
	@include sans-serif-bold;
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	letter-spacing: inherit;

	@include mediaquery(xl) {
		&:hover {
		}
	}

}

.full-link {
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 4;
}

small {
	font-size: 12px;
	@include sans-serif-bold;
}

mark {
	background-color: transparent;
}


em, i {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-style: italic;
}

hr {
	background: #dddddd;
	border: 0;
	height: 1px;
	margin: 25px auto;
}

.a-link {
	color: color('text', 'link');
	transition: color .4s ease-out;

	@include mediaquery(xl) {
		&:hover {
			color: color('background', 'green');
		}
	}
}

p, .body1 {
	font-size: 16px;
	letter-spacing: .5px;
	line-height: 1.6;
	margin-bottom: $gap;
	@include sans-serif-regular;

	a {
		color: color('text', 'link');
		transition: color .4s ease-out;

		@include mediaquery(xl) {
			&:hover {
				color: color('background', 'green');
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	strong, b {
		@include sans-serif-bold;
	}
}

.category-item {
	@extend .body1;
	cursor: pointer;

	a {
		color: black;

	}
	
	@include mediaquery(xl) {
		&:hover {
			color: color('background', 'green');
		}
	}

	&.activeBio {
		border-bottom:1px solid color('background', 'green');
	}
}

.categories-meta {
	border-top:1px solid color('background', 'green');
	font-size: 10px;
	line-height: 1.4;
}

.bio {
	display: none;

	&.activeBio {
		display: block;
	}
}

.share-link {
	font-size: 20px;
	margin-right: 20px;

	&:last-child {
		margin-right: 0;
	}

	@include mediaquery(xl) {
		&:hover {
			color: color('background', 'green');
		}
	}
}

.component-share {
	@include mediaquery(large) {
		text-align: right;
	}
}

.meta {
	font-size: 16px;
	line-height: 2;
	letter-spacing: 1.5px;
	position: relative;
	padding-left: 20px;
	@include sans-serif-regular;

	&:before {
		content: '\2022';
		position: absolute;
		left: 5px;
		top: -7px;
	}

	&:first-child{
		padding-left: 0;

		&:before {
			content: '';
		}

	}
}


.eyebrow, .caption2 {
	font-size: 12px;
	line-height: 1.16;
	text-transform: uppercase;
	margin-bottom: 10px;
	letter-spacing: .5px;
	@include sans-serif-regular;
}



.cursor-pointer {
	cursor: pointer;

	@include mediaquery(xl) {
		&:hover {
			color: color('background', 'green');
		}
	}
}

span {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
}

u {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
}

strong, b {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	font-weight: 700;
}

th, tr, td {
	color: inherit;
}



.ctas {
	clear: both;
}


/***** Error & Message Styles *****/
.error {
	@extend .caption !optional;
}





// Use dynamically generated color classes as follows:
//
// Classes:
//
// 		.background-key-variant
// 		.color-color-key-variant
//
// For example:
//
// 		.background-accent-blue
// 		.color-accent-blue
//
.underline {
	// for use in WYSIWYG as <u></u> was deprecated in 4.7+
	color:inherit;
	display: inline;
	font-family: inherit;
	font-size:inherit;
	font-style:inherit;
	font-weight:inherit;
	line-height: inherit;
	text-decoration:underline;
}

.lowercase {
	text-transform: lowercase;
}

.nocase {
	text-transform: none;
}

.uppercase {
	text-transform: uppercase;
}


/**** Headline Styles ****/

.headline1 {
	font-size: 36px;
	line-height: 1.3;
	letter-spacing: .5px;
	@include sans-serif-semi;

}

.headline2 {
	font-size: 28px;
	line-height: 1.3;
	letter-spacing: .5px;
	@include sans-serif-semi;

}


.headline3 {
	font-size: 18px;
	line-height: 1.2;
	letter-spacing: .5px;
	@include sans-serif-semi;

}

.headline4 {
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .5px;
	@include sans-serif-semi;
}

.headline5 {
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .5px;
	@include sans-serif-regular;

}


.display-block {
	display: block;
}

.row-text-white {
	color: $white;
}

.row-text-black {
	color: color('text', 'navy');
}

/**** Body Copy ****/
.body-text {
	p {
		color: inherit;
	}
	//inline images
	img, .post-img {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
		width: auto;

		&.alignleft, &.post-img-left {
			display: block;
			margin: $gap*5 auto;
			max-width: 100%;
			width: auto;

			@include mediaquery(medium) {
				clear: left;
				float: left;
				margin: $gap*5 $gap*5 $gap*5 0; 
			}
		}

		&.alignright, &.post-img-right {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(medium) {
				clear: right;
				float: right;
				margin: $gap*5 0 $gap*5 $gap*5;

			}
		}

		&.aligncenter, &.post-img-none {
			display: block;
			margin: $gap*5 auto $gap*5;
			max-width: 100%;
			width: auto;
		}
	}

	.wp-caption {
		&.aligncenter, &.post-img-none {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;
		}

		&.alignleft {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(medium) {
				clear: left;
				float: left;
				margin: 0 $gap*2.5 $gap*2.5 0;
			}
		}

		&.alignright {
			display: block;
			margin: 0 auto $gap*5;
			max-width: 100%;
			width: auto;

			@include mediaquery(medium) {
				clear: right;
				float: right;
				margin: 0 0 $gap*2.5 $gap*2.5;

				&.single {
					margin: 0 -10% $gap*2.5 $gap*2.5;
				}
			}
		}
		.wp-caption-text {
			@extend .caption !optional;
			margin-top: $gap*.5;
		}
	}

	blockquote {
		padding: 0 $gap*2;

		@include mediaquery(large){
			padding: 0 $gap*3;
		}
	}

	cite {
		@extend .body1;
		font-style: italic;
		font-size: 12px;
	}

	table {
		td {
			@extend .body1;
			border: 1px solid color('standard', 'gray');
			padding: $gap*.5;
		}
	}

	//ctas and links
	& + a {
		margin-top: $gap*1.5;
	}

	+ .ctas {
		// Add margin to ctas if it's next to a .body-text div
		margin-top: $gap*1.5;
	}

	.button a {
		color: inherit;
		text-decoration: none;
	}

	a, p a, ul li a, ol li a {
		color: color('text', 'link');
		transition: color .4s ease-out;

		@include mediaquery(xl) {
			&:hover {
				color: color('background', 'green');
			}
		}
	}

	//colors
	.row-text-white & {
		color: $white;
	}

	.row-text-black & {
		color: color('text', 'navy');
	}

	//list styles
	ul, ol {
		margin-left: $gap*.75;
		margin-bottom: $gap*5;

		@include mediaquery(large){
			margin-left: 85px;
		}

		li {
			@extend .body1;
			color: inherit;
			margin-bottom: $gap*.5;
		}
	}

	ul {
		li {
			list-style-type: disc;
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	h1 {
		@extend .headline2 !optional;
	}

	h2 {
		@extend .headline3 !optional;
	}

	h3 {
		@extend .headline4 !optional;

	}

	h4 {
		@extend .headline4 !optional;

	}

	h5 {
		@extend .headline5 !optional;

	}

	h6 {
		@extend .headline6 !optional;

	}
}

/**** Override & Helper Classes ****/
.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-left-mobile {
	@include mediaquery-max(large){
		text-align: left;
	}
}

.align-right-mobile {
	@include mediaquery-max(large){
		text-align: right;
	}
}

.align-center-mobile {
	@include mediaquery-max(large){
		text-align: center;
	}
}

.no-margin {
	margin: 0;
}

.display-block {
	display: block;
}
